import styled from 'styled-components';

export const Container = styled.div.attrs(
  ({ smallMode }: { smallMode: boolean }) => ({
    smallMode,
  })
)`
  display: inline-block !important;
  width: auto !important;
  max-width: ${(props) => (props.smallMode ? '340px !important' : 'none')};

  & > .inner-container {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;

    & > .event-title-container {
      margin-left: 15px;
    }
  }

  .title-wrapper {
    display: inline-flex;
    font-size: 11px;
    align-items: center;
  }

  .tag {
    margin: 5px 0 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.smallMode ? '120px' : '250px')};
  }
`;

export const TitleWrapper = styled.div.attrs(
  ({ smallMode, mediumMode }: { smallMode: boolean; mediumMode: boolean }) => ({
    smallMode,
    mediumMode,
  })
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 11px;

  .title {
    font-size: 13px;
    max-width: ${(props) =>
      props.smallMode ? '250px' : props.mediumMode ? '350px' : '800px'};
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media only screen and (max-width: 1650px) {
      max-width: ${(props) =>
        props.smallMode ? '250px' : props.mediumMode ? '350px' : '600px'};
    }

    @media only screen and (max-width: 1440px) {
      max-width: ${(props) =>
        props.smallMode ? '250px' : props.mediumMode ? '350px' : '450px'};
    }

    @media only screen and (max-width: 1300px) {
      max-width: ${(props) =>
        props.smallMode ? '250px' : props.mediumMode ? '350px' : '250px'};
    }

    @media only screen and (max-width: 1100px) {
      max-width: 125px;
    }
  }

  & > .event-details {
    margin-bottom: 5px;
  }

  & > .text-with-icon {
    & .icon {
      margin-right: 8px;
    }
  }
`;
