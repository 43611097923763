import * as React from 'react';
import { Column } from '../../components/common/data-list/types';
import { Artist, FilterArtistsDocument } from '../../resolver.types';
import { DataList } from '../../components/common/data-list/data-list.component';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DateFormats } from '../../utils/date-formats';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../route-urls';
import { Col } from 'antd';
import TextField from '../../components/filters/inputs/common/text-field/text-field.component';
import { ArtistNameColumn } from './artists.page.styles';

export const ArtistsPage = () => {
  const navigate = useNavigate();

  const stopEventPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => event.stopPropagation();

  const columns: Array<Column<Artist>> = [
    {
      title: 'Artists ({count})',
      dataIndex: 'name',
      sortable: true,
      render: (_, record) => {
        let display = record.name;

        if (record.externalID) {
          display = `${display} (${record.externalID})`;
        }

        return (
          <ArtistNameColumn onClick={stopEventPropagation}>
            <Link to={routes.setArtistData(record.id)}>
              <span style={{ color: '#000' }}>{display}</span>
            </Link>
          </ArtistNameColumn>
        );
      },
    },
    {
      title: 'Assignments',
      dataIndex: 'relatedBrandedContainersCount',
      sortable: true,
      render: (_, record) => {
        if (record.relatedBrandedContainersCount! > 0) {
          return (
            <div
              style={{ width: 'fit-content' }}
              onClick={stopEventPropagation}
            >
              <Link
                style={{ textDecoration: 'underline' }}
                to={{
                  pathname: routes.brandedContainersForArtist(record.id),
                }}
              >
                {record.relatedBrandedContainersCount}
              </Link>
            </div>
          );
        }

        return <span>0</span>;
      },
    },
    {
      title: 'Birthday',
      render: (_, record) => {
        return record.dateOfBirth
          ? format(parseISO(record.dateOfBirth), DateFormats.date)
          : '-';
      },
    },
    {
      title: 'Country',
      dataIndex: 'countryOfOrigin',
      render: (_, record) =>
        record.countryOfOrigin ? record.countryOfOrigin : '-',
    },
    {
      title: 'Genre',
      dataIndex: 'genres',
      render: (_, record) =>
        record.genres && record.genres.length > 0
          ? record.genres.join(', ')
          : '-',
    },
    {
      title: 'URL',
      dataIndex: 'externalDataSource',
      render: (_, record) => (record.source ? record.source : '-'),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      render: (_, record) => (
        <div>
          <div>On: {format(parseISO(record.createdAt), DateFormats.date)}</div>
          <div>From: {record.createdBy}</div>
        </div>
      ),
    },
  ];

  const onSelectArtist = (record: Artist) => {
    navigate(routes.setArtistData(record.id));
  };

  return (
    <DataList<Artist>
      onRowClick={onSelectArtist}
      columns={columns}
      filters={[
        <Col key={2} md={6} xs={12}>
          <TextField
            label="Artist name"
            name="name"
            placeholder="Artist name..."
          />
        </Col>,
        <Col key={1} md={6} xs={12}>
          <TextField label="Artist ID" placeholder="Artist ID..." name="id" />
        </Col>,
      ]}
      query={FilterArtistsDocument}
      dataType="artists"
    />
  );
};
