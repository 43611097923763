import React from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { Container, DeleteButton } from './edit-bottom-bar.styles';

type EditDataBottomBarProps = {
  onCancel: () => void;
  onRemove: () => void;
  onSubmit: () => void;
  submitDisabled?: boolean;
  cancelDisabled?: boolean;
  deleteDisabled?: boolean;
};

export const EditDataBottomBar = ({
  onCancel,
  onRemove,
  onSubmit,
  submitDisabled = false,
  cancelDisabled = false,
  deleteDisabled = false,
}: EditDataBottomBarProps) => {
  return (
    <Container className="edit-bottom-bar">
      <div className="left-side">
        <DeleteButton onClick={onRemove} disabled={deleteDisabled}>
          <DeleteOutlined />
          Delete branded container
        </DeleteButton>
      </div>
      <div className="right-side">
        <Button
          icon={<CloseOutlined />}
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          className="submit"
          disabled={submitDisabled}
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </Container>
  );
};
