import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 260px);
  height: 62px;

  background-color: #f3f3f3;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  & > .right-side {
    display: flex;
    & button {
      margin-left: 10px;
    }

    & .ant-btn {
      font-size: 12px;
    }
  }
`;

export const DeleteButton = styled(Button)`
  &.ant-btn {
    font-size: 11px;
    border: none;
  }
  &.ant-btn:not(:hover) {
    background-color: inherit;
    color: #555555;
  }
`;
