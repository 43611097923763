import styled from 'styled-components';

export const ArtistNameColumn = styled.div`
  width: fit-content;

  max-width: 900px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1600px) {
    max-width: 800px;
  }

  @media only screen and (max-width: 1440px) {
    max-width: 700px;
  }

  @media only screen and (max-width: 1300px) {
    max-width: 550px;
  }

  @media only screen and (max-width: 1100px) {
    max-width: 350px;
  }
`;
